// Generated by Framer (8857874)

import { addFonts, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["h5GWOZFvY"];

const serializationHash = "framer-jh1we"

const variantClassNames = {h5GWOZFvY: "framer-v-dbc8rm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "h5GWOZFvY", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-dbc8rm", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"h5GWOZFvY"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 215, 140)", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, ...style}}><motion.div className={"framer-38zg92"} data-border data-framer-name={"Stack"} layoutDependency={layoutDependency} layoutId={"M1eBVue1p"} style={{"--border-bottom-width": "2px", "--border-color": "rgb(255, 255, 255)", "--border-left-width": "2px", "--border-right-width": "2px", "--border-style": "solid", "--border-top-width": "2px", backgroundColor: "rgba(255, 255, 255, 0.6)", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16}}><Image background={{alt: "", fit: "fill", sizes: `calc(${componentViewport?.width || "100vw"} * 1.2899 - 20px)`, src: "https://framerusercontent.com/images/2SzRNK33DZqZ82tmPKfdGnhXUHQ.png", srcSet: "https://framerusercontent.com/images/2SzRNK33DZqZ82tmPKfdGnhXUHQ.png?scale-down-to=512 512w,https://framerusercontent.com/images/2SzRNK33DZqZ82tmPKfdGnhXUHQ.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/2SzRNK33DZqZ82tmPKfdGnhXUHQ.png?scale-down-to=2048 2048w,https://framerusercontent.com/images/2SzRNK33DZqZ82tmPKfdGnhXUHQ.png 3024w"}} className={"framer-lvkf4n"} data-framer-name={"image"} layoutDependency={layoutDependency} layoutId={"iI2zYdlXS"} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10}}/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jh1we.framer-7w887p, .framer-jh1we .framer-7w887p { display: block; }", ".framer-jh1we.framer-dbc8rm { height: 425px; overflow: hidden; position: relative; width: 536px; will-change: var(--framer-will-change-override, transform); }", ".framer-jh1we .framer-38zg92 { align-content: center; align-items: center; bottom: -124px; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 10px; position: absolute; right: -208px; width: 129%; }", ".framer-jh1we .framer-lvkf4n { aspect-ratio: 1.4144059869036483 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 475px); position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-jh1we .framer-38zg92 { gap: 0px; } .framer-jh1we .framer-38zg92 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-jh1we .framer-38zg92 > :first-child { margin-left: 0px; } .framer-jh1we .framer-38zg92 > :last-child { margin-right: 0px; } }", ".framer-jh1we[data-border=\"true\"]::after, .framer-jh1we [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 425
 * @framerIntrinsicWidth 536
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerHOG0YCeBc: React.ComponentType<Props> = withCSS(Component, css, "framer-jh1we") as typeof Component;
export default FramerHOG0YCeBc;

FramerHOG0YCeBc.displayName = "Mockup";

FramerHOG0YCeBc.defaultProps = {height: 425, width: 536};

addFonts(FramerHOG0YCeBc, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})